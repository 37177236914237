<template>
    <section class="home-hero">
        <v-container fluid fill-height class="home-hero__content">
            <div class="d-flex align-center justify-center fill-height">
            <v-row>
                <v-col>
                    <v-img src="/assets/img/logo_white_kousai.png" class="top-logo mx-auto" :width="imageWidth"/>
                    <p class="top-text">Enter</p>
                </v-col>
            </v-row>
            </div>
        </v-container>
    </section>
</template>
<style>
.home-hero__content {
    width: 100%;
    height: 100svh;
    background-size: cover;
    background-position: center center;
    background-image: url("/public/assets/img/31136170_l.jpg");
}
.top-logo{
    filter: drop-shadow(0px 0px 5px rgb(0, 0, 0));
    padding-bottom: 40px;
}
.top-text{
    padding: 0 10px 0 10px;
    color: #fff;
    font-size: 250%;
    font-style: italic;
    text-shadow: 2px 2px 10px #111 ,
    -2px 2px 10px #111 ,
    2px -2px 10px #111 ,
    -2px -2px 10px #111;
    text-decoration-line: underline;
}

.top-text:hover{
    color: rgba(97, 99, 0, 0.5);
    text-shadow: 2px 2px 10px #fff9aa ,
    -2px 2px 10px #fff9aa ,
    2px -2px 10px #fff9aa ,
    -2px -2px 10px #fff9aa;
}
@media screen and (max-width: 960px) {
    .top-logo{
        width: 100%;
    }
}
@media screen and (min-width: 960px) {
    .top-logo{
        width: 30%;
    }
}


</style>
