<template>
  <v-app>
    <AppHeader/>
    <v-main>
      <TopPage/>
    </v-main>
    <AppFooter/>
  </v-app>
</template>

<script>
import TopPage from './components/TopPage.vue'
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    TopPage,
    AppHeader,
    AppFooter,
  }
}
</script>

<style>
@font-face {
  font-family: "新レトロ丸ゴシックM";
  src: url("/public/assets/fonts/ShinRetroMaruGothic-Medium.ttf") format("truetype");
}
#app {
  font-family: "新レトロ丸ゴシックM", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
